import React from 'react';
import * as Styled from './faqContentHeaderStyles';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import { useEffect } from 'react';

const FaqContentHeader = ({ sectionData, location }) => {
  useEffect(async () => {
    if (sectionData?.elementId === location?.hash?.replace('#', '')) {
      const waitedID = await new Promise((resolve) => {
        document?.getElementById(sectionData?.elementId) && resolve(true);
      });
      if (waitedID) {
        setTimeout(() => {
          window.location.href = `#${sectionData?.elementId}`;
        }, 500);
      }
    }
  }, [sectionData?.elementId]);

  const optionsMainStyle = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Styled.ParaText>{children}</Styled.ParaText>
      ),
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text]),
  };

  return (
    <Styled.FaqWrapper>
      <Styled.SpanStyle id={sectionData?.elementId}></Styled.SpanStyle>
      <Styled.Body className="py-0">
        <hr />
      </Styled.Body>
      <Styled.Body>
        {sectionData?.header?.trim() && (
          <Styled.Header2>{sectionData?.header}</Styled.Header2>
        )}

        <Styled.DescriptionText>
          {sectionData?.contentDetails?.raw &&
            documentToReactComponents(
              JSON.parse(sectionData?.contentDetails?.raw),
              optionsMainStyle
            )}
        </Styled.DescriptionText>
      </Styled.Body>
    </Styled.FaqWrapper>
  );
};
export default FaqContentHeader;
