import styled from 'styled-components';
export const Header2 = styled.h2`
  margin-bottom: 35px;
  position: relative;
  font-family: 'Adelle-Bold', sans-serif;
  font-size: 40px;
  line-height: 45px;
  color: #0e4c80;
  padding: 0;
  font-weight: normal;
  font-style: normal;
  @media (max-width: 1439px) {
    font-size: 40px;
    line-height: 45px;
    margin-bottom: 30px;
  }
  @media (max-width: 1199px) {
    font-size: 30px;
    line-height: 45px;
    margin-bottom: 30px;
  }
  @media (max-width: 767px) {
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 20px;
  }
`;

export const FaqWrapper = styled.div`
  hr {
    display: none;
  }
  & + & {
    hr {
      display: block;
    }
  }
`;

export const Body = styled.div`
  position: relative;
  padding: 20px 90px;
  @media (max-width: 991px) {
    padding: 35px;
  }
  @media (max-width: 767px) {
    padding: 35px;
  }
`;

export const SpanStyle = styled.span`
  position: relative;
  top: -35px;
  @media (max-width: 991px) {
    top: 0px;
  }
`;

export const ParaText = styled.div`
  color: rgba(0, 0, 0, 0.8);
  font-family: 'Figtree', arial, sans-serif;
  font-size: 18px;
  line-height: 32px;
  /* letter-spacing: 0.02em; */
  position: relative;
  padding: 0;
  margin-bottom: 20px;
  a > code {
    display: inline-block;
    position: relative;
    top: 0;
    padding: 15px 35px;
    margin-right: 10px;
    margin-bottom: 40px;
    font-family: 'MrAlex', sans-serif;
    font-size: 24px;
    line-height: 28px;
    text-transform: uppercase;
    font-weight: bold;
    color: #00a6e8;
    border: 2px solid #00a6e8;
    box-shadow: 0 0 0 2px rgb(255 255 255 / 0%);
    border-radius: 100px;
    cursor: pointer;
    text-decoration: none;
    /* code {
      font: inherit;
    } */
    @media (max-width: 1399px) {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 30px;
    }
    @media (max-width: 1199px) {
      font-size: 25px;
      line-height: 25px;
    }

    @media (max-width: 767px) {
      font-size: 22px;
      line-height: 25px;
    }
    :hover {
      top: -5px;
      color: #f7991c;
      border-color: #f7991c;
      box-shadow: 0 5px 0 0 #f7991c;
    }
  }
  @media (max-width: 767px) {
    font-size: 15px;
    line-height: 22px;
  }
  i {
    font-style: italic;
    margin-bottom: 0;
    font-size: 18px;
    line-height: 24px;
    @media (max-width: 1399px) {
      font-size: 15px;
      line-height: 22px;
    }
    @media (max-width: 767px) {
      font-size: 13px;
      line-height: 20px;
    }
  }
`;

export const DescriptionText = styled(ParaText)`
  /* padding: 0 20px; */
  @media (max-width: 991px) {
    padding: 0px;
  }
`;
